import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import ClearIcon from '@mui/icons-material/Clear';

interface KeywordFilterProps {
  keyword: string;
  onChange: (value: string) => void;
}

const KeywordFilter = ({ keyword, onChange }: KeywordFilterProps) => {
  const onClear = () => onChange('');
  return (
    <FormControl orientation="horizontal">
      <FormLabel>
        <Typography level="title-md">Filter</Typography>
      </FormLabel>
      <Input
        size="sm"
        sx={{ width: 350 }}
        placeholder="Pal name or paldex #"
        value={keyword}
        onChange={e => onChange(e.target.value)}
        endDecorator={
          <IconButton onClick={onClear}>
            <ClearIcon />
          </IconButton>
        }
      />
    </FormControl>
  );
};

export default KeywordFilter;
