import Box from '@mui/joy/Box';
import { HOME_BANNER, LOGO_NAME } from '../utils/assets/constants';

const HomeBanner = () => {
  return (
    <Box
      style={{
        position: 'relative',
        width: '100%',
        overflow: 'clip',
      }}
      borderRadius={20}>
      <img
        src={HOME_BANNER}
        style={{ width: '100%', filter: 'contrast(0.85)' }}
      />
      <Box
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          display: 'flex',
        }}>
        <img
          src={LOGO_NAME}
          style={{ margin: 'auto', filter: 'contrast(1.9)', height: '70%' }}
        />
      </Box>
    </Box>
  );
};

export default HomeBanner;
