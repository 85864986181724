import React, { useState } from 'react';
import { useAppSelector } from '../hooks';
import { getAllPals, Pal } from '../reducers/palsSlice';
import { getParentCombosByParentId } from '../reducers/parentCombosSlice';
import Stack from '@mui/joy/Stack';
import { Divider } from '@mui/joy';
import PalPickerContent from './palPicker/PalPickerContent';
import ParentCombos, {
  ParentCombosByVariant,
} from './parentCombos/ParentCombos';

function OtherParentFinder() {
  const [selectedParentPal, setSelectedParentPal] = useState<Pal>();
  const possibleParentCombs = useAppSelector(state =>
    selectedParentPal
      ? getParentCombosByParentId(state, selectedParentPal.id)
      : undefined,
  );
  const pals = useAppSelector(getAllPals);

  function onSelectParent(pal: Pal) {
    if (selectedParentPal?.id !== pal.id) {
      setSelectedParentPal(pal);
    } else {
      setSelectedParentPal(undefined);
    }
  }

  return (
    <Stack spacing={2} direction="row" sx={{ height: '100%' }}>
      <Stack
        sx={{
          minWidth: '650px',
          width: '50%',
          overflow: 'auto',
          flexGrow: 1,
          flexShrink: 1,
          height: '100%',
        }}
        spacing={1}>
        <PalPickerContent
          headerText={'Pick a parent'}
          pals={pals}
          onSelectPal={onSelectParent}
          selectedPal={selectedParentPal}
        />
      </Stack>
      <Divider orientation="vertical" />
      <ParentCombos
        parentCombos={possibleParentCombs}
        byVariant={ParentCombosByVariant.PARENT}
      />
    </Stack>
  );
}

export default OtherParentFinder;
