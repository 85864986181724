import { configureStore } from '@reduxjs/toolkit';
import palsReducer from './reducers/palsSlice';
import parentCombosReducer from './reducers/parentCombosSlice';

const store = configureStore({
  reducer: {
    pals: palsReducer,
    parentCombos: parentCombosReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
