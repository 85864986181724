import AddIcon from '@mui/icons-material/Add';
import ForwardIcon from '@mui/icons-material/Forward';
import Stack from '@mui/joy/Stack';
import { useAppSelector } from '../../hooks';
import { getPalById } from '../../reducers/palsSlice';
import PalCard from '../palCard/Card';
import React from 'react';
import { ParentCombo } from '../../reducers/parentCombosSlice';

const PARENTS_PAIR_STYLE = {
  alignItems: 'center',
  padding: 1,
  width: 'fit-content',
  border: '2px solid var(--joy-palette-warning-500)',
  borderRadius: '1rem',
};

interface ParentsPairProps {
  parentCombo: ParentCombo;
  showChild?: boolean;
}

function ParentsPair({ parentCombo, showChild = false }: ParentsPairProps) {
  const { parent1, parent2, child } = parentCombo;
  const parent1Pal = useAppSelector(state => getPalById(state, parent1));
  const parent2Pal = useAppSelector(state => getPalById(state, parent2));
  const childPal = useAppSelector(state => getPalById(state, child));
  const childContent = showChild ? (
    <>
      <ForwardIcon />
      <PalCard pal={childPal} />
    </>
  ) : null;
  return (
    <Stack direction="row" spacing={1} sx={PARENTS_PAIR_STYLE}>
      <PalCard pal={parent1Pal} />
      <AddIcon />
      <PalCard pal={parent2Pal} />
      {childContent}
    </Stack>
  );
}

export default ParentsPair;
