export const PalTypes = [
  'fire',
  'grass',
  'ground',
  'water',
  'ice',
  'dragon',
  'dark',
  'neutral',
  'electric',
] as const;

export type PalType = (typeof PalTypes)[number];

export const WorkTypes = [
  'kindling',
  'watering',
  'planting',
  'electric',
  'handiwork',
  'gathering',
  'lumbering',
  'mining',
  'medicine',
  'cooling',
  'transporting',
  'farming',
] as const;

export type WorkType = (typeof WorkTypes)[number];

export enum RarityCategory {
  COMMON = 1,
  RARE,
  EPIC,
  LEGENDARY,
}

export const RarityCategories: RarityCategory[] = [
  RarityCategory.LEGENDARY,
  RarityCategory.EPIC,
  RarityCategory.RARE,
  RarityCategory.COMMON,
];

export interface Language {
  code: string;
  name: string;
}

export const Languages: Language[] = [
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'zh',
    name: '中文',
  },
];
