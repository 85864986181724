import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import ListITem from '@mui/joy/ListItem';
import { Languages } from '../../utils/constants';

const STYLE = {
  width: '100%',
  padding: '0.5rem',
  margin: '0 0.5rem',
};

const LanguageSwitch: React.FC = () => {
  const { i18n } = useTranslation();
  return (
    <ListITem>
      <Select defaultValue={i18n.resolvedLanguage} style={STYLE}>
        {Languages.map(language => (
          <Option
            value={language.code}
            key={language.code}
            onClick={() => {
              i18n.changeLanguage(language.code);
            }}>
            {language.name}
          </Option>
        ))}
      </Select>
    </ListITem>
  );
};

export default LanguageSwitch;
