import React, { useState } from 'react';
import { useAppSelector } from '../hooks';
import { getAllPals, Pal } from '../reducers/palsSlice';
import { getParentCombosByChildId } from '../reducers/parentCombosSlice';
import Stack from '@mui/joy/Stack';
import PalPickerContent from './palPicker/PalPickerContent';
import { Divider } from '@mui/joy';
import ParentCombos, {
  ParentCombosByVariant,
} from './parentCombos/ParentCombos';

// user selects 1 desired child, list all the parents combinations
function ParentsFinder() {
  const pals = useAppSelector(getAllPals);
  const [selectedChildPal, setSelectedChildPal] = useState<Pal>();
  const parentCombos = useAppSelector(state =>
    selectedChildPal
      ? getParentCombosByChildId(state, selectedChildPal.id)
      : undefined,
  );

  const onSelectPal = (pal: Pal) => {
    if (selectedChildPal?.id !== pal.id) {
      setSelectedChildPal(pal);
    } else {
      setSelectedChildPal(undefined);
    }
  };

  return (
    <Stack spacing={2} direction="row" sx={{ height: '100%' }}>
      <Stack
        sx={{
          minWidth: '650px',
          width: '50%',
          overflow: 'auto',
          flexGrow: 1,
          flexShrink: 1,
          height: '100%',
        }}
        spacing={1}>
        <PalPickerContent
          headerText={'Pick a child'}
          pals={pals}
          onSelectPal={onSelectPal}
          selectedPal={selectedChildPal}
        />
      </Stack>
      <Divider orientation="vertical" />
      <ParentCombos
        parentCombos={parentCombos}
        byVariant={ParentCombosByVariant.CHILD}
      />
    </Stack>
  );
}

export default ParentsFinder;
