import { Routes, Route } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import { ROUTES } from './contants';

const PWBreederRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {ROUTES.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
      </Route>
    </Routes>
  );
};

export default PWBreederRouter;
