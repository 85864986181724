import FormControl from '@mui/joy/FormControl';
import IconButtonFilter from '../buttonFilterhoc/ButtonFilter';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import { PalType, PalTypes } from '../../../../utils/constants';
import { getTypeImageUrl } from '../../../../utils/palDetailUtils';

interface PalTypeButtonFilterProps {
  onTypeClick: (type: PalType) => void;
  selectedType?: PalType;
}

export const PalTypeButtonFilter = ({
  onTypeClick,
  selectedType,
}: PalTypeButtonFilterProps) => {
  const selectedOptions = selectedType ? [selectedType] : [];
  const renderButtonContent = (type: PalType) => (
    <img src={getTypeImageUrl(type)} />
  );
  return (
    <FormControl orientation="horizontal">
      <FormLabel>
        <Typography level="title-md">Type</Typography>
      </FormLabel>
      <IconButtonFilter
        options={[...PalTypes]}
        selectedOptions={selectedOptions}
        onOptionClick={onTypeClick}
        renderButtonContent={renderButtonContent}
      />
    </FormControl>
  );
};
