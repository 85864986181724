import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';

interface WorkInProgressPageProps {
  title: string;
}

const WorkInProgressPageContent = ({ title }: WorkInProgressPageProps) => {
  return (
    <Stack spacing={2}>
      <Typography level="h2">{title}</Typography>
      <Typography>
        <i>Stay tuned. Coming soon...</i>
      </Typography>
    </Stack>
  );
};

export default WorkInProgressPageContent;
