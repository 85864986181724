import { Pal } from '../reducers/palsSlice';
import { ParentCombo } from '../reducers/parentCombosSlice';

export const getParentIdsFromCombos = (parentCombos: ParentCombo[]) => {
  const parentSet = new Set<string>();
  for (const combo of parentCombos) {
    parentSet.add(combo.parent1);
    parentSet.add(combo.parent2);
  }
  return parentSet;
};

export const getChildIdsFromCombos = (parentCombos: ParentCombo[]) => {
  const childSet = new Set<string>();
  for (const combo of parentCombos) {
    combo.child && childSet.add(combo.child);
  }
  return childSet;
};

export const getFilterParentCombosByParent =
  (selectedFilterPal: Pal) => (parentCombo: ParentCombo) => {
    const { parent1, parent2 } = parentCombo;
    return parent1 === selectedFilterPal.id || parent2 === selectedFilterPal.id;
  };

export const getFilterParentCombosByChild =
  (selectedFilterPal: Pal) => (parentCombo: ParentCombo) => {
    const { child } = parentCombo;
    return child === selectedFilterPal.id;
  };
