import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/joy/Avatar';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Tooltip from '@mui/joy/Tooltip';
import { Pal } from '../../reducers/palsSlice';
import {
  getPalImageUrl,
  getRarityCategory,
  getTypeImageUrlsFromPal,
  getWorkImageUrlAndLevelFromPal,
} from '../../utils/palDetailUtils';
import { TypographySystem } from '@mui/joy/styles/types/typography';
import RarityChip from './RarityChip';
import React from 'react';

const SIZES = {
  sm: {
    cardSize: '8em',
    avatarSize: '3.5em',
    nameSize: 'title-sm',
    workIconsScaleThreshbold: 4,
    paldexIndexScaleThreshold: 3,
    rarityChipScale: 0.85,
  },
  md: {
    cardSize: '9.5em',
    avatarSize: '5em',
    nameSize: 'title-lg',
    workIconsScaleThreshbold: undefined,
    paldexIndexScaleThreshold: 3,
    rarityChipScale: undefined,
  },
  lg: {
    cardSize: '13em',
    avatarSize: '6em',
    nameSize: 'title-lg',
    workIconsScaleThreshbold: undefined,
    paldexIndexScaleThreshold: undefined,
    rarityChipScale: undefined,
  },
};

const getSquareSize = (width: string) => ({
  width,
  height: width,
});

const CARD_HIGHLIGHT_STYLE = {
  borderColor: 'var(--joy-palette-warning-400)',
  transform: 'scale(1.05)',
  transitionDuration: '0.1s',
  zIndex: 50,
};

const CARD_HOVER_STYLE = {
  '&:hover': CARD_HIGHLIGHT_STYLE,
};

const PALDEX_INDEX_STYLE = {
  position: 'absolute',
  bottom: '0.1em',
  right: '0.3em',
};

const NAME_STYLE = { textAlign: 'center', textWrap: 'nowrap' };

const TYPES_STYLE = {
  position: 'absolute',
  top: '0.3em',
  left: '0.3em',
};

const WORK_TYPES_STYLE = {
  position: 'absolute',
  top: '0.5em',
  right: '0.5em',
  maxHeight: '60%',
  img: {
    width: '1.5em',
    height: '1.5em',
  },
};

const EMPTY_STATE_STYLE = { width: '100%', height: '100%' };

interface PalCardEmptyStateProps {
  tooltip?: string;
}

const PalCardEmptyState = ({ tooltip }: PalCardEmptyStateProps) => {
  return (
    <Tooltip
      arrow
      title={tooltip}
      variant="solid"
      placement="right"
      color="warning">
      <AddIcon sx={EMPTY_STATE_STYLE} />
    </Tooltip>
  );
};

interface PalCardProps {
  pal?: Pal;
  size?: keyof typeof SIZES;
  hoverAnimation?: boolean;
  onClick?: () => void;
  highlighted?: boolean;
  emptyTooltip?: string;
}

const PalCard = ({
  pal,
  onClick,
  emptyTooltip,
  size = 'md',
  hoverAnimation = false,
  highlighted = false,
}: PalCardProps) => {
  const {
    cardSize,
    avatarSize,
    nameSize,
    workIconsScaleThreshbold,
    paldexIndexScaleThreshold,
    rarityChipScale,
  } = SIZES[size];
  const typeIcons = pal
    ? getTypeImageUrlsFromPal(pal).map((url, idx) => (
        <img key={idx} src={url} />
      ))
    : [];
  const workIcons = pal
    ? getWorkImageUrlAndLevelFromPal(pal).map(([url, level], idx) => (
        <Stack
          key={idx}
          direction="row"
          sx={{ alignItems: 'center', margin: '-3px' }}>
          <img src={url} />
          <Typography level="body-xs">{level}</Typography>
        </Stack>
      ))
    : [];
  const workIconsScaleStyle = workIconsScaleThreshbold &&
    workIcons.length >= workIconsScaleThreshbold && {
      transform: `scale(${workIconsScaleThreshbold / workIcons.length})`,
      transformOrigin: 'top',
    };
  const paldexIndex = pal?.id ?? '';
  const paldexIndexScaleStyle = paldexIndexScaleThreshold &&
    paldexIndex.length >= paldexIndexScaleThreshold && {
      transform: `scale(${paldexIndexScaleThreshold / paldexIndex.length})`,
      transformOrigin: 'right',
    };
  const cardContent = pal ? (
    <CardContent
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
      }}>
      <Avatar
        src={getPalImageUrl(pal.id)}
        sx={{ ...getSquareSize(avatarSize) }}
      />
      <Typography level={nameSize as keyof TypographySystem} sx={NAME_STYLE}>
        {pal.name}
      </Typography>
      <Typography
        level="body-xs"
        sx={{ ...PALDEX_INDEX_STYLE, ...paldexIndexScaleStyle }}>
        #{pal.id}
      </Typography>
      <Stack sx={TYPES_STYLE} spacing={0.5}>
        {typeIcons}
      </Stack>
      <Stack sx={{ ...WORK_TYPES_STYLE, ...workIconsScaleStyle }}>
        {workIcons}
      </Stack>
      <RarityChip
        rarityCategory={getRarityCategory(pal.rarity)}
        scale={rarityChipScale}
      />
    </CardContent>
  ) : (
    <PalCardEmptyState tooltip={emptyTooltip} />
  );
  return (
    <Card
      variant="outlined"
      sx={{
        ...(hoverAnimation && CARD_HOVER_STYLE),
        ...(highlighted && CARD_HIGHLIGHT_STYLE),
        ...getSquareSize(cardSize),
        ...(onClick && { cursor: 'pointer' }),
        flexShrink: 0,
      }}
      onClick={onClick}>
      {cardContent}
    </Card>
  );
};

export default PalCard;
