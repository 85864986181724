import { BrowserRouter } from 'react-router-dom';
import PWBreederRoutes from './routes/PWBreederRoutes';
import { CssVarsProvider } from '@mui/joy/styles/CssVarsProvider';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box/Box';
import React from 'react';
import './i18n';
import '@fontsource/inter';

function App() {
  return (
    <Box className="App">
      <BrowserRouter>
        <CssVarsProvider defaultMode="dark">
          <CssBaseline />
          <PWBreederRoutes />
        </CssVarsProvider>
      </BrowserRouter>
    </Box>
  );
}

export default App;
