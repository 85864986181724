import AutocompleteOption from '@mui/joy/AutocompleteOption/AutocompleteOption';
import { Pal } from '../../../reducers/palsSlice';
import {
  Avatar,
  ListItemContent,
  ListItemDecorator,
  Stack,
  Typography,
} from '@mui/joy';
import {
  getPalImageUrl,
  getRarityCategory,
  getTypeImageUrlsFromPal,
} from '../../../utils/palDetailUtils';
import RarityChip from '../../palCard/RarityChip';

interface PalPickerOptionProps {
  pal: Pal;
  size?: 'sm' | 'md' | 'lg';
}

const PalPickerOption = (props: PalPickerOptionProps) => {
  const { pal, size, ...restProps } = props;
  const { id, name } = pal;
  const typeIcons = getTypeImageUrlsFromPal(pal).map((url, idx) => (
    <img key={idx} src={url} />
  ));
  const rarityCategory = getRarityCategory(pal.rarity);
  return (
    <AutocompleteOption {...restProps}>
      <ListItemDecorator>
        <Avatar src={getPalImageUrl(pal.id)} size={size} />
      </ListItemDecorator>
      <ListItemContent sx={{ fontSize: 'sm' }}>
        <Stack sx={{ paddingLeft: 1 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography level="body-md">{name}</Typography>
            <Typography level="body-sm">#{id}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" spacing={0.5}>
              {typeIcons}
            </Stack>
            <RarityChip rarityCategory={rarityCategory} />
          </Stack>
        </Stack>
      </ListItemContent>
    </AutocompleteOption>
  );
};

export default PalPickerOption;
