import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { getIsDevEnv } from './utils/envUtils';

i18n.use(initReactI18next).use(LanguageDetector).use(Backend).init({
  debug: getIsDevEnv(),
  fallbackLng: 'en',
});

export default i18n;
