import { useMemo } from 'react';
import { filterPals } from '../../../utils/palFilterUtils';
import { Pal } from '../../../reducers/palsSlice';
import { useKeywordFilterHooks } from './keyword/hooks';
import { usePalTypeButtonFilterHooks } from './palType/hooks';
import { useRarityCategoryButtonFilterHooks } from './rarityCategory/hooks';
import { useWorkTypeButtonFilterHooks } from './workType/hooks';

export const usePalPickerFilters = (pals: Pal[]) => {
  const { debouncedKeyword, ...keywordFilterProps } = useKeywordFilterHooks();
  const { selectedType, onTypeButtonClick } = usePalTypeButtonFilterHooks();
  const { selectedRarityCategory, onRarityCategoryButtonClick } =
    useRarityCategoryButtonFilterHooks();
  const { selectedWorkTypes, onWorkTypeButtonClick, onWorkTypeClearAllClick } =
    useWorkTypeButtonFilterHooks();
  const filteredPals = useMemo(
    () =>
      filterPals(
        pals,
        debouncedKeyword,
        selectedType ? [selectedType] : undefined,
        selectedWorkTypes,
        selectedRarityCategory ? [selectedRarityCategory] : undefined,
      ),
    [
      pals,
      debouncedKeyword,
      selectedType,
      selectedWorkTypes,
      selectedRarityCategory,
    ],
  );

  return {
    // Keyword filter props
    ...keywordFilterProps,
    // Pal type filter props
    selectedType,
    onTypeButtonClick,
    // Rarity Category filter props
    selectedRarityCategory,
    onRarityCategoryButtonClick,
    // Work type filter props
    selectedWorkTypes,
    onWorkTypeButtonClick,
    onWorkTypeClearAllClick,
    // Filtered pal results
    filteredPals,
  };
};
