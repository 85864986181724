import { useState } from 'react';
import { PalType } from '../../../../utils/constants';

export const usePalTypeButtonFilterHooks = () => {
  const [selectedType, setSelectedType] = useState<PalType>();
  const onTypeButtonClick = (type: PalType) => {
    setSelectedType(selectedType => (selectedType === type ? undefined : type));
  };

  return {
    selectedType,
    onTypeButtonClick,
  };
};
