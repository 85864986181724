import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import { RarityCategory, RarityCategories } from '../../../../utils/constants';
import IconButtonFilter from '../buttonFilterhoc/ButtonFilter';
import { getRarityCategoryName } from '../../../../utils/palDetailUtils';

interface RarityCategoryButtonFilterProps {
  onCategoryClick: (type: RarityCategory) => void;
  selectedCategroy?: RarityCategory;
}

export const RarityCategoryButtonFilter = ({
  onCategoryClick,
  selectedCategroy,
}: RarityCategoryButtonFilterProps) => {
  const selectedOptions = selectedCategroy ? [selectedCategroy] : [];
  const renderButtonContent = (category: RarityCategory) => {
    const rarityName = getRarityCategoryName(category);
    return <Typography>{rarityName}</Typography>;
  };
  return (
    <FormControl orientation="horizontal">
      <FormLabel>
        <Typography level="title-md">Rarity</Typography>
      </FormLabel>
      <IconButtonFilter
        options={[...RarityCategories]}
        selectedOptions={selectedOptions}
        onOptionClick={onCategoryClick}
        renderButtonContent={renderButtonContent}
      />
    </FormControl>
  );
};
