import { Pal } from '../reducers/palsSlice';
import {
  PalTypes,
  RarityCategories,
  RarityCategory,
  WorkType,
  WorkTypes,
} from './constants';
import { getRarityCategory, getWorkTypeFromPal } from './palDetailUtils';

const stringifyPalForFilter = (pal: Pal) =>
  [pal.name, pal.id].join('|').toLowerCase();

const getIsPalMatch = (
  pal: Pal,
  keyword: string = '',
  types: string[] = [...PalTypes],
  workTypes: WorkType[] = [...WorkTypes],
  rarityCategories: RarityCategory[] = [...RarityCategories],
) => {
  // Type does not match
  if (!types.includes(pal.type1) && !types.includes(pal.type2 ?? '')) {
    return false;
  }

  // Work does not match
  const palWorkTypes = getWorkTypeFromPal(pal);
  if (!workTypes.every(workType => palWorkTypes.includes(workType))) {
    return false;
  }

  // Rarity does not match
  if (!rarityCategories.includes(getRarityCategory(pal.rarity))) {
    return false;
  }

  // Keyword does not match when provided
  if (keyword && !stringifyPalForFilter(pal).includes(keyword.toLowerCase())) {
    return false;
  }
  return true;
};

export const filterPals = (
  pals: Pal[],
  keyword?: string,
  types?: string[],
  workTypes?: WorkType[],
  rarityCategories?: RarityCategory[],
) =>
  pals.filter(pal =>
    getIsPalMatch(pal, keyword, types, workTypes, rarityCategories),
  );
