import Stack from '@mui/joy/Stack';
import { Pal } from '../../reducers/palsSlice';
import PalCard from '../palCard/Card';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import { usePalPickerFilters } from './filters/hooks';
import PalPickerFilters from './filters/PalPickerFilters';
import React, { useState } from 'react';

const PalEmptyState = () => (
  <Typography>No pals meet the filter conditions.</Typography>
);

interface PalPickerContentProps {
  pals: Pal[];
  selectedPal?: Pal;
  onSelectPal: (pal: Pal) => void;
  headerText: string;
}

const PalPickerContent = ({
  pals,
  selectedPal,
  onSelectPal,
  headerText,
}: PalPickerContentProps) => {
  const { filteredPals, ...filtersProps } = usePalPickerFilters(pals);

  const palsContent =
    filteredPals.length > 0 ? (
      filteredPals.map(pal => (
        <PalCard
          size="sm"
          key={pal.id}
          pal={pal}
          hoverAnimation
          onClick={() => onSelectPal(pal)}
          highlighted={selectedPal?.id === pal.id}
        />
      ))
    ) : (
      <PalEmptyState />
    );

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Typography level="h3">{headerText}</Typography>
        <Stack
          direction="row"
          sx={{ flexWrap: 'wrap', alignItems: 'center' }}
          spacing={2}>
          <PalCard pal={selectedPal} emptyTooltip={'Pick a pal below'} />
          <PalPickerFilters {...filtersProps} />
        </Stack>
      </Stack>
      <Divider />
      <Stack
        direction="row"
        sx={{
          padding: 1,
          gap: 2,
          flexWrap: 'wrap',
          overflow: 'scroll',
          alignContent: 'baseline',
          flexShrink: 1,
          flexGrow: 1,
        }}>
        {palsContent}
      </Stack>
    </>
  );
};

export default PalPickerContent;
