import React from 'react';
import Stack from '@mui/joy/Stack';
import Tab from '@mui/joy/Tab';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import Typography from '@mui/joy/Typography';
import ParentsFinder from '../components/ParentsFinder';
import OtherParentFinder from '../components/OtherParentFinder';

const BREEDING_TABS = [
  {
    tabName: 'By Child',
    tabPanel: <ParentsFinder />,
  },
  {
    tabName: 'By Parent',
    tabPanel: <OtherParentFinder />,
  },
];

const BreedingPage = () => {
  return (
    <Stack sx={{ width: '100%', height: '100%' }} spacing={2}>
      <Typography level="h2" sx={{ flexGrow: 0, flexShrink: 0 }}>
        Breeding
      </Typography>
      <Tabs
        defaultValue={0}
        sx={{ overflow: 'scroll', display: 'flex', flexGrow: 1 }}>
        <TabList size="lg">
          {BREEDING_TABS.map(({ tabName }) => (
            <Tab key={tabName}>{tabName}</Tab>
          ))}
        </TabList>
        {BREEDING_TABS.map(({ tabName, tabPanel }, idx) => (
          <TabPanel
            key={tabName}
            value={idx}
            sx={{ flexGrow: 1, flexShrink: 1, overflow: 'scroll' }}>
            {tabPanel}
          </TabPanel>
        ))}
      </Tabs>
    </Stack>
  );
};

export default BreedingPage;
