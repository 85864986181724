import { createSlice } from '@reduxjs/toolkit';
import {
  getChildToParentCombosMap,
  getParentComboList,
  getParentToParentCombosMap,
} from '../data/utils';

export interface ParentCombo {
  id: string;
  child: string;
  parent1: string;
  parent2: string;
}

type ComboMap = Record<string, ParentCombo[]>;

interface ParentCombosState {
  combos: ParentCombo[];
  childToComboMap: ComboMap;
  parentToComboMap: ComboMap;
}

const childToComboMap = getChildToParentCombosMap();
const parentToComboMap = getParentToParentCombosMap();
console.log('parentToComboMap', parentToComboMap);
const combos = getParentComboList(childToComboMap);

const initialState: ParentCombosState = {
  combos,
  childToComboMap,
  parentToComboMap,
};

export const parentCombosSlice = createSlice({
  name: 'parentCombos',
  initialState,
  reducers: {},
  selectors: {
    getAllParentCombos: (state: ParentCombosState) => state.combos,
    getParentCombosByChildId: (state: ParentCombosState, childId: string) =>
      state.childToComboMap[childId],
    getParentCombosByParentId: (state: ParentCombosState, parentId: string) =>
      state.parentToComboMap[parentId],
  },
});

export const {
  getAllParentCombos,
  getParentCombosByChildId,
  getParentCombosByParentId,
} = parentCombosSlice.selectors;

export default parentCombosSlice.reducer;
