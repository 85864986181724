import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { Link } from 'react-router-dom';
import CottageIcon from '@mui/icons-material/Cottage';

const NotFoundPage = () => {
  return (
    <Stack
      sx={{
        height: '100%',
        justifyContent: 'space-around',
      }}>
      <Stack spacing={2} sx={{ alignItems: 'center' }}>
        <Stack direction="row" sx={{ margin: 'auto', alignItems: 'center' }}>
          <Typography sx={{ fontSize: 192 }}>4</Typography>
          <img
            src="/android-chrome-192x192.png"
            style={{ aspectRatio: '1 / 1' }}
          />
          <Typography sx={{ fontSize: 192 }}>4</Typography>
        </Stack>
        <Typography level="h1">Ooops. Page not found</Typography>
        <Button
          component={Link}
          to="/"
          startDecorator={<CottageIcon />}
          color="warning">
          Back to home page
        </Button>
      </Stack>
    </Stack>
  );
};

export default NotFoundPage;
