import { Outlet } from 'react-router-dom';
import Stack from '@mui/joy/Stack';
import LeftHandRailNavigation from './LeftRailNavigation';
import RightHandRail from './RightHandRail';
import Sheet from '@mui/joy/Sheet';

const LAYOUT_STYLES = {
  height: '100dvh',
  width: '100dvw',
};

const MAIN_CONTENT_STYLES = {
  padding: '0 1em',
  height: '100%',
  flexGrow: 1,
  flexShrink: 1,
};

const Layout = () => {
  return (
    <Stack className="layout" direction="row" sx={LAYOUT_STYLES}>
      <LeftHandRailNavigation />
      <Stack component="main" sx={MAIN_CONTENT_STYLES}>
        <Sheet sx={{ width: '100%', height: '100%', padding: '1rem' }}>
          <Outlet />
        </Sheet>
      </Stack>
      <RightHandRail />
    </Stack>
  );
};

export default Layout;
