import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

export const useKeywordFilterHooks = (debouncedTime: number = 300) => {
  const [keyword, setKeyword] = useState('');
  const [debouncedKeyword, setDebouncedKeyword] = useState('');
  const debouncedSetKeyword = debouncedTime
    ? useCallback(debounce(setDebouncedKeyword, debouncedTime), [
        setDebouncedKeyword,
        debouncedTime,
      ])
    : undefined;

  useEffect(() => {
    debouncedSetKeyword?.(keyword);
    return () => debouncedSetKeyword?.cancel();
  }, [keyword]);

  return {
    keyword,
    debouncedKeyword: debouncedTime ? debouncedKeyword : keyword,
    onKeywordInputChange: setKeyword,
  };
};
