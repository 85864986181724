import Sheet from '@mui/joy/Sheet';
import { getIsFeatureEnabled } from '../../features/featureUtils';
import { Feature } from '../../features/features';

const RIGHT_HAND_RAIL_STYLE = {
  width: '230px',
  flexShrink: 0,
  flexGrow: 0,
};

const isRHRAdEnabled = getIsFeatureEnabled(Feature.RHR_VERTICAL_ADS);

const RightHandRail = () => {
  return (
    <Sheet sx={RIGHT_HAND_RAIL_STYLE}>
      {isRHRAdEnabled && (
        <>
          <ins
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-9974308172641361"
            data-ad-slot="3793456450"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
          <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
        </>
      )}
    </Sheet>
  );
};

export default RightHandRail;
