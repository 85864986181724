import Chip from '@mui/joy/Chip';
import {
  getRarityCategoryColor,
  getRarityCategoryName,
} from '../../utils/palDetailUtils';
import Typography from '@mui/joy/Typography';
import { RarityCategory } from '../../utils/constants';

interface RarityChipProps {
  rarityCategory: RarityCategory;
  scale?: number;
}

const RarityChip = ({ rarityCategory, scale }: RarityChipProps) => {
  const scaleStyle = scale && {
    transform: `scale(${scale})`,
    transformOrigin: 'top',
  };
  const rarityName = getRarityCategoryName(rarityCategory);
  const rarityColor = getRarityCategoryColor(rarityCategory);
  return (
    <Chip size="sm" sx={{ backgroundColor: rarityColor, ...scaleStyle }}>
      <Typography level="title-sm">{rarityName}</Typography>
    </Chip>
  );
};

export default RarityChip;
