import { Autocomplete, Avatar, createFilterOptions } from '@mui/joy';
import { Pal } from '../../../reducers/palsSlice';
import DropdownOption from './DropdownOption';
import { useState } from 'react';
import { getPalImageUrl } from '../../../utils/palDetailUtils';

interface PalPickerDropdownProps {
  pals: Pal[];
  selectedPal: Pal | null;
  onSelect: (pal: Pal | null) => void;
  inputValue: string;
  onInputChange: (value: string) => void;
  size?: 'sm' | 'md' | 'lg';
}

const PalPickerDropdown = ({
  pals,
  selectedPal,
  onSelect,
  inputValue,
  onInputChange,
  size,
}: PalPickerDropdownProps) => {
  const getOptionLabel = (pal: Pal) => pal.name;
  const isOptionEqualToValue = (pal: Pal, value: Pal) => pal.id === value.id;
  const filterOptions = createFilterOptions({
    stringify: (pal: Pal) => pal.name + pal.id,
  });
  const onChange = (_0: any, value: Pal | null) => {
    onSelect(value);
  };
  const renderOption = (
    props: Omit<React.HTMLAttributes<HTMLLIElement>, 'color'>,
    pal: Pal,
  ) => <DropdownOption key={pal.id} pal={pal} {...props} />;
  const startDecorator = selectedPal ? (
    <Avatar
      src={getPalImageUrl(selectedPal.id)}
      size={size}
      sx={{ transform: 'scale(0.9)' }}
    />
  ) : null;
  return (
    <Autocomplete
      sx={{ minWidth: 250 }}
      size={size}
      options={pals}
      multiple={false}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={onChange}
      value={selectedPal}
      startDecorator={startDecorator}
      filterOptions={filterOptions}
      inputValue={inputValue}
      onInputChange={(_, value) => onInputChange(value)}
    />
  );
};

export default PalPickerDropdown;
