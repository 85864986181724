import { useReducer } from 'react';
import { Pal } from '../../reducers/palsSlice';
import { ParentCombo } from '../../reducers/parentCombosSlice';

interface ParentCombosState {
  allParentCombos: ParentCombo[];
  filteredParentCombos: ParentCombo[];
  selectedFilterPal: Pal | null;
  inputValue: string;
}

const PARENT_COMBOS_INITIAL_STATE: ParentCombosState = {
  allParentCombos: [],
  filteredParentCombos: [],
  selectedFilterPal: null,
  inputValue: '',
};

enum ParentCombosStateActionType {
  SELECT_FILTER_PAL = 'SELECT_FILTER_PAL',
  CHANGE_PARENT_COMBOS = 'CHANGE_PARENT_COMBO',
  CHANGE_INPUT_VALUE = 'CHANGE_INPUT_VALUE',
}

interface SelectParentAction {
  type: ParentCombosStateActionType.SELECT_FILTER_PAL;
  filterPal: Pal | null;
}

interface ChangeParentCombo {
  type: ParentCombosStateActionType.CHANGE_PARENT_COMBOS;
  parentCombos: ParentCombo[];
}

interface ChangeInputValue {
  type: ParentCombosStateActionType.CHANGE_INPUT_VALUE;
  inputValue: string;
}

type ParentCombosStateAction =
  | SelectParentAction
  | ChangeParentCombo
  | ChangeInputValue;

type GetFilterParentCombos = (
  selectedFilterPal: Pal,
) => (combo: ParentCombo) => boolean;

const getParentComboReducer = (
  getFilterParentCombos: GetFilterParentCombos,
) => {
  const parentCombosReducer = (
    state: ParentCombosState,
    action: ParentCombosStateAction,
  ): ParentCombosState => {
    switch (action.type) {
      case ParentCombosStateActionType.SELECT_FILTER_PAL:
        const selectedFilterPal = action.filterPal;
        const filteredParentCombos = selectedFilterPal
          ? state.allParentCombos.filter(
              getFilterParentCombos(selectedFilterPal),
            )
          : state.allParentCombos;
        return {
          ...state,
          selectedFilterPal,
          filteredParentCombos,
        };
      case ParentCombosStateActionType.CHANGE_PARENT_COMBOS:
        const allParentCombos = action.parentCombos;
        return {
          ...state,
          allParentCombos,
          filteredParentCombos: allParentCombos,
          selectedFilterPal: null,
          inputValue: '',
        };
      case ParentCombosStateActionType.CHANGE_INPUT_VALUE:
        return {
          ...state,
          inputValue: action.inputValue,
        };
      default:
        return state;
    }
  };
  return parentCombosReducer;
};

export const useParentCombosState = (
  filterParentCombos: GetFilterParentCombos,
) => {
  const parentCombosReducer = getParentComboReducer(filterParentCombos);
  const [
    { filteredParentCombos, selectedFilterPal: selectedParent, inputValue },
    dispatch,
  ] = useReducer(parentCombosReducer, PARENT_COMBOS_INITIAL_STATE);

  const onSelectFilterPal = (parent: Pal | null) =>
    dispatch({
      type: ParentCombosStateActionType.SELECT_FILTER_PAL,
      filterPal: parent,
    });

  const onParentCombosChange = (parentCombos: ParentCombo[]) => {
    dispatch({
      type: ParentCombosStateActionType.CHANGE_PARENT_COMBOS,
      parentCombos,
    });
  };

  const onInputValueChange = (inputValue: string) => {
    dispatch({
      type: ParentCombosStateActionType.CHANGE_INPUT_VALUE,
      inputValue,
    });
  };

  return {
    filteredParentCombos,
    selectedParent,
    inputValue,
    onSelectFilterPal,
    onParentCombosChange,
    onInputValueChange,
  };
};
