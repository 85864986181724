import { Pal } from '../reducers/palsSlice';
import { getAssetUrl } from './assets/assetsUtils';
import { PalType, RarityCategory, WorkType, WorkTypes } from './constants';

export const getPalImageUrl = (palId: string, size: 128 | 256 | 512 = 128) =>
  getAssetUrl(`/pals/${size}/${palId}.png`);

export const getTypeImageUrl = (type: PalType) =>
  getAssetUrl(`/types/${type}.webp`);

export const getWorkImageUrl = (work: WorkType) =>
  getAssetUrl(`/work_types/${work}.png`);

export const getTypeImageUrlsFromPal = (pal: Pal) => {
  const urls: string[] = [];
  for (const type of [pal.type1, pal.type2]) {
    if (type) {
      const url = getTypeImageUrl(type);
      url && urls.push(url);
    }
  }
  return urls;
};

export const getWorkImageUrlAndLevelFromPal = (pal: Pal) => {
  const urlAndLevels: Array<[string, number]> = [];
  for (const work of WorkTypes) {
    const workLevel = pal[work as keyof Pal] as number;
    const url = getWorkImageUrl(work);
    if (url && workLevel) {
      urlAndLevels.push([url, workLevel]);
    }
  }
  return urlAndLevels;
};

export const getRarityCategory = (rarity: number) => {
  if (rarity <= 4) {
    return RarityCategory.COMMON;
  }

  if (rarity <= 7) {
    return RarityCategory.RARE;
  }

  if (rarity <= 10) {
    return RarityCategory.EPIC;
  }

  return RarityCategory.LEGENDARY;
};

const RARITY_CATEGORY_NAME_MAP = new Map([
  [RarityCategory.COMMON, 'Common'],
  [RarityCategory.RARE, 'Rare'],
  [RarityCategory.EPIC, 'Epic'],
  [RarityCategory.LEGENDARY, 'Legendary'],
]);

export const RARITY_CHIP_COLOR = new Map<RarityCategory, string>([
  [RarityCategory.COMMON, 'var(--joy-palette-neutral-700)'],
  [RarityCategory.RARE, 'var(--joy-palette-success-500)'],
  [RarityCategory.EPIC, 'var(--joy-palette-primary-500)'],
  [RarityCategory.LEGENDARY, 'var(--joy-palette-warning-500)'],
]);

export const getRarityCategoryName = (rarityCategory: RarityCategory) => {
  return RARITY_CATEGORY_NAME_MAP.get(rarityCategory);
};

export const getRarityCategoryColor = (rarityCategory: RarityCategory) => {
  return RARITY_CHIP_COLOR.get(rarityCategory);
};

export const getWorkTypeFromPal = (pal: Pal) => {
  const workTypes: WorkType[] = [];
  for (const workType of WorkTypes) {
    if (pal[workType]) {
      workTypes.push(workType);
    }
  }
  return workTypes;
};
