import { createSlice } from '@reduxjs/toolkit';
import { PalType } from '../utils/constants';
import { getPaldexMap, getPals } from '../data/utils';

export interface Pal {
  id: string;
  name: string;
  type1: PalType;
  type2?: PalType;
  nocturnal: boolean;
  kindling?: number;
  watering?: number;
  planting?: number;
  electric?: number;
  handiwork?: number;
  gathering?: number;
  lumbering?: number;
  mining?: number;
  medicine?: number;
  cooling?: number;
  transporting?: number;
  farming?: number;
  food: number;
  breedPower: number;
  hp: number;
  melee: number;
  shot: number;
  defence: number;
  price: number;
  staminaSpeed: number;
  walkingSpeed: number;
  runningSpeed: number;
  mountedSpeed: number;
  transportingSpeed: number;
  captureMultiplier: number;
  malePercentage: number;
  femalePercentage: number;
  rarity: number;
}

type PaldexMap = Record<string, Pal>;

interface PalsState {
  pals: Pal[];
  paldexMap: PaldexMap;
}

const pals = getPals();
const paldexMap = getPaldexMap(pals);

const initialState: PalsState = { pals, paldexMap };

export const palsSlice = createSlice({
  name: 'pals',
  initialState,
  reducers: {},
  selectors: {
    getAllPals: ({ pals }: PalsState) => pals,
    getPalById: ({ paldexMap }: PalsState, id: string) => paldexMap[id],
    getPalsByIds: ({ paldexMap }: PalsState, ids: string[]) =>
      ids.map(id => paldexMap[id]),
  },
});

export const { getAllPals, getPalById, getPalsByIds } = palsSlice.selectors;

export default palsSlice.reducer;
