import { useLocation, useNavigate } from 'react-router-dom';
import { FUNCTION_ROUTES, MISC_ROUTES, Route } from '../../routes/contants';
import {
  Box,
  List,
  ListDivider,
  ListItem,
  ListItemButton,
  Sheet,
  Stack,
  Typography,
} from '@mui/joy';

import React from 'react';
import LanguageSwitch from '../languageSwitch';
import { useTranslation } from 'react-i18next';
import { LOGO_NAME } from '../../utils/assets/constants';
import { getIsFeatureEnabled } from '../../features/featureUtils';
import { Feature } from '../../features/features';

const HEADER_STYLES = {
  margin: 1,
  cursor: 'pointer',
};

interface DrawerListItemProps {
  route: Route;
}

const NAVIGATION_ITEM_STYLE = {
  borderRadius: '2rem',
  padding: '0.5rem',
  margin: '0 0.5rem',
};

const NavigationItem = ({ route }: DrawerListItemProps) => {
  const { t } = useTranslation();
  const { name, path, icon: Icon } = route;
  const navigate = useNavigate();
  const onClick = () => navigate('/' + path);
  const location = useLocation();
  const isCurrentRoute = path
    ? location.pathname.startsWith(`/${path}`)
    : location.pathname === '/';
  const color = isCurrentRoute ? 'warning' : undefined;
  return (
    <ListItem>
      <ListItemButton onClick={onClick} style={NAVIGATION_ITEM_STYLE}>
        <Stack
          direction="row"
          sx={{
            width: '100%',
            alignItems: 'center',
            gap: 1,
          }}>
          <Icon color={color} />
          <Typography level="h4" color={color}>
            {t(name)}
          </Typography>
        </Stack>
      </ListItemButton>
    </ListItem>
  );
};

const LEFT_HAND_RAIL_NAV_BAR_STYLES = {
  width: '230px',
  flexShrink: 0,
  flexGrow: 0,
};
const LOGO_IMAGE_STYLES = { margin: '1em 0', width: '100%' };
const NAV_LIST_STYLES = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
};

const MISC_STACK_STYLES = { marginBottom: '3em' };

const LeftHandRailNavigation = () => {
  const navigate = useNavigate();
  const isLanguageSwitchEnabled = getIsFeatureEnabled(Feature.I18N_SWITCH);
  return (
    <Sheet style={LEFT_HAND_RAIL_NAV_BAR_STYLES}>
      <List sx={NAV_LIST_STYLES}>
        <Box sx={HEADER_STYLES} onClick={() => navigate('/')}>
          <img src={LOGO_NAME} style={LOGO_IMAGE_STYLES} />
        </Box>
        {isLanguageSwitchEnabled && <LanguageSwitch />}
        <Stack sx={{ flexGrow: 1, flexShrink: 0 }}>
          {FUNCTION_ROUTES.map(route => (
            <NavigationItem key={route.name} route={route} />
          ))}
        </Stack>
        <ListDivider />
        <Stack sx={MISC_STACK_STYLES}>
          {MISC_ROUTES.map(route => (
            <NavigationItem key={route.name} route={route} />
          ))}
        </Stack>
      </List>
    </Sheet>
  );
};

export default LeftHandRailNavigation;
