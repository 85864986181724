import Stack from '@mui/joy/Stack';
import { ParentCombo } from '../../reducers/parentCombosSlice';
import ParentsPair from './ParentsPair';
import Typography from '@mui/joy/Typography';
import ParentCombosFilter from './filters/ParentCombosFilter';
import Divider from '@mui/joy/Divider';
import {
  getFilterParentCombosByParent,
  getChildIdsFromCombos,
  getParentIdsFromCombos,
  getFilterParentCombosByChild,
} from '../../utils/comboFilterUtils';
import { useAppSelector } from '../../hooks';
import { getPalsByIds } from '../../reducers/palsSlice';
import React, { useEffect, useRef } from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import Box from '@mui/joy/Box';
import { useParentCombosState } from './hooks';

export enum ParentCombosByVariant {
  CHILD = 'CHILD',
  PARENT = 'PARENT',
}

interface ParentCombosProps {
  parentCombos?: ParentCombo[];
  byVariant: ParentCombosByVariant;
}

const VARIANT_RESOURCE_MAP = {
  [ParentCombosByVariant.PARENT]: {
    getFilterHelper: getFilterParentCombosByChild,
    getFilterOptionsHelper: getChildIdsFromCombos,
    filterFieldName: 'Filter by child',
    showChild: true,
  },
  [ParentCombosByVariant.CHILD]: {
    getFilterHelper: getFilterParentCombosByParent,
    getFilterOptionsHelper: getParentIdsFromCombos,
    filterFieldName: 'Filter by parent',
    showChild: false,
  },
};

const ParentCombos = ({ parentCombos = [], byVariant }: ParentCombosProps) => {
  const {
    getFilterHelper,
    getFilterOptionsHelper,
    filterFieldName,
    showChild,
  } = VARIANT_RESOURCE_MAP[byVariant];
  const {
    filteredParentCombos,
    selectedParent,
    inputValue,
    onSelectFilterPal,
    onParentCombosChange,
    onInputValueChange,
  } = useParentCombosState(getFilterHelper);
  const filterOptionsPalIds = [...getFilterOptionsHelper(parentCombos)];
  const filterOptions = useAppSelector(state =>
    getPalsByIds(state, filterOptionsPalIds),
  );
  const virtuosoHandleRef = useRef<VirtuosoHandle | null>(null);
  const virtuosoHandle = virtuosoHandleRef.current;

  useEffect(() => {
    onParentCombosChange(parentCombos);
  }, [parentCombos]);

  useEffect(() => {
    if (virtuosoHandle) {
      virtuosoHandle.scrollToIndex(0);
    }
  }, [parentCombos, virtuosoHandle]);

  return (
    <Stack spacing={1} sx={{ flexGrow: 0, flexShrink: 0, width: 600 }}>
      <Typography level="h3">Parent Combos</Typography>
      <ParentCombosFilter
        filterFieldName={filterFieldName}
        filterOptions={filterOptions}
        selectedParent={selectedParent}
        onSelect={onSelectFilterPal}
        inputValue={inputValue}
        onInputChange={onInputValueChange}
      />
      <Divider />
      {filteredParentCombos.length === 0 && (
        <Typography>Pick a child to view parent combos</Typography>
      )}
      <Virtuoso
        ref={virtuosoHandleRef}
        style={{ height: '100%' }}
        totalCount={filteredParentCombos.length}
        data={filteredParentCombos}
        itemContent={(_, combo) => (
          <Box sx={{ padding: 1 }}>
            <ParentsPair
              key={combo.id}
              parentCombo={combo}
              showChild={showChild}
            />
          </Box>
        )}
      />
    </Stack>
  );
};

export default ParentCombos;
