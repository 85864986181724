import Stack from '@mui/joy/Stack';
import PalPickerDropdown from '../../palPicker/dropdown/Dropdown';
import { Pal } from '../../../reducers/palsSlice';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';

interface ParentCombosFilterProps {
  filterOptions?: Pal[];
  filterFieldName: string;
  selectedParent: Pal | null;
  onSelect: (pal: Pal | null) => void;
  inputValue: string;
  onInputChange: (value: string) => void;
}

const ParentCombosFilter = ({
  filterOptions = [],
  selectedParent,
  onSelect,
  inputValue,
  onInputChange,
  filterFieldName,
}: ParentCombosFilterProps) => {
  return (
    <Stack spacing={1}>
      <FormControl orientation="horizontal">
        <FormLabel>
          <Typography level="title-md">{filterFieldName}</Typography>
        </FormLabel>
        <PalPickerDropdown
          size="sm"
          pals={filterOptions}
          selectedPal={selectedParent}
          onSelect={onSelect}
          inputValue={inputValue}
          onInputChange={onInputChange}
        />
      </FormControl>
    </Stack>
  );
};

export default ParentCombosFilter;
