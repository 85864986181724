import { useState } from 'react';
import { WorkType } from '../../../../utils/constants';

export const useWorkTypeButtonFilterHooks = () => {
  const [selectedWorkTypes, setSelectedWorkTypes] = useState<WorkType[]>([]);
  const onWorkTypeButtonClick = (type: WorkType) => {
    if (selectedWorkTypes.includes(type)) {
      setSelectedWorkTypes(types => types.filter(t => t !== type));
    } else {
      setSelectedWorkTypes(types => [...types, type]);
    }
  };
  const onWorkTypeClearAllClick = () => setSelectedWorkTypes([]);

  return {
    selectedWorkTypes,
    onWorkTypeButtonClick,
    onWorkTypeClearAllClick,
  };
};
