import { useState } from 'react';
import { RarityCategory } from '../../../../utils/constants';

export const useRarityCategoryButtonFilterHooks = () => {
  const [selectedRarityCategory, setSelectedRarityCategory] =
    useState<RarityCategory>();

  const onRarityCategoryButtonClick = (category: RarityCategory) => {
    setSelectedRarityCategory(selectedCategory =>
      selectedCategory === category ? undefined : category,
    );
  };
  return {
    selectedRarityCategory,
    onRarityCategoryButtonClick,
  };
};
