import EggIcon from '@mui/icons-material/Egg';
import WorkIcon from '@mui/icons-material/PrecisionManufacturing';
import EmailIcon from '@mui/icons-material/Email';
import PolicyIcon from '@mui/icons-material/Policy';
import CottageIcon from '@mui/icons-material/Cottage';
import BreedingPage from '../pages/BreedingPage';
import { SvgIconComponent } from '@mui/icons-material/';
import HomePage from '../pages/HomePage';
import WorkInProgressPageContent from '../pages/WorkInProgressPage';
import ContactPage from '../pages/ContactUsPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import NotFoundPage from '../pages/NotFoundPage';
import React from 'react';

export interface Route {
  path: string;
  name: string;
  icon: SvgIconComponent;
  element: JSX.Element;
}

// TODO: elements are just placeholder for now
export const FUNCTION_ROUTES = [
  {
    path: '',
    element: <HomePage />,
    name: 'HOME_PAGE_NAME',
    icon: CottageIcon,
  },
  {
    path: 'breeding',
    element: <BreedingPage />,
    name: 'BREEDING_PAGE_NAME',
    icon: EggIcon,
  },
  {
    path: 'work',
    element: <WorkInProgressPageContent title="Work" />,
    name: 'WORK_PAGE_NAME',
    icon: WorkIcon,
  },
];

export const MISC_ROUTES = [
  {
    path: 'about',
    element: <ContactPage />,
    name: 'CONTACT_US_PAGE_NAME',
    icon: EmailIcon,
  },
  {
    path: 'privacy-policy',
    element: <PrivacyPolicyPage />,
    name: 'PRIVACY_POLICY_PAGE_NAME',
    icon: PolicyIcon,
  },
];

export const FALLBACK_ROUNTES = [
  {
    path: '*',
    element: <NotFoundPage />,
    name: 'Not Found',
    icon: EmailIcon,
  },
];

export const DEFINED_ROUTES = [...FUNCTION_ROUTES, ...MISC_ROUTES];

export const ROUTES: Route[] = [...DEFINED_ROUTES, ...FALLBACK_ROUNTES];
