import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import { WorkType, WorkTypes } from '../../../../utils/constants';
import { getWorkImageUrl } from '../../../../utils/palDetailUtils';
import IconButtonFilter from '../buttonFilterhoc/ButtonFilter';

interface WorkTypeButtonFilterProps {
  onTypeClick: (type: WorkType) => void;
  onClearAllClick: () => void;
  selectedTypes: WorkType[];
}

const WorkTypeButtonFilter = ({
  onTypeClick,
  onClearAllClick,
  selectedTypes,
}: WorkTypeButtonFilterProps) => {
  const renderButtonContent = (type: WorkType) => (
    <img style={{ height: '24px' }} src={getWorkImageUrl(type)} />
  );
  return (
    <FormControl orientation="horizontal">
      <FormLabel>
        <Typography level="title-md">Work</Typography>
      </FormLabel>
      <IconButtonFilter
        isButtonGroup
        options={[...WorkTypes]}
        onOptionClick={onTypeClick}
        selectedOptions={selectedTypes}
        onClearAllClick={onClearAllClick}
        renderButtonContent={renderButtonContent}
      />
    </FormControl>
  );
};

export default WorkTypeButtonFilter;
