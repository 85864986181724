import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import EmailIcon from '@mui/icons-material/Email';

const ContactPage = () => {
  return (
    <Stack sx={{ paddingTop: '2em', alignItems: 'center' }} spacing={2}>
      <Typography level="body-lg">
        Please email <b>contact@pwbreeder.com</b> for questions, bugs, issues or
        feature requests. We will try our best to address them.
      </Typography>
      <Button
        component="a"
        href="mailto:contact@pwbreeder.com"
        color="warning"
        startDecorator={<EmailIcon />}>
        Email us
      </Button>
    </Stack>
  );
};

export default ContactPage;
