import Stack from '@mui/joy/Stack';
import KeywordFilter from './keyword/KeywordFilter';
import { PalTypeButtonFilter } from './palType/PalTypeButtonFilter';
import WorkTypeButtonFilter from './workType/WorkTypeButtonFilter';
import { RarityCategoryButtonFilter } from './rarityCategory/RarityCategoryButtonFilter';
import { PalType, RarityCategory, WorkType } from '../../../utils/constants';

interface PalPickerFiltersProps {
  keyword: string;
  selectedType: PalType | undefined;
  selectedWorkTypes: WorkType[];
  selectedRarityCategory: RarityCategory | undefined;
  onKeywordInputChange: (value: string) => void;
  onTypeButtonClick: (type: PalType) => void;
  onWorkTypeButtonClick: (workType: WorkType) => void;
  onWorkTypeClearAllClick: () => void;
  onRarityCategoryButtonClick: (category: RarityCategory) => void;
}

const PalPickerFilters = ({
  keyword,
  selectedType,
  selectedWorkTypes,
  selectedRarityCategory,
  onKeywordInputChange,
  onTypeButtonClick,
  onWorkTypeButtonClick,
  onWorkTypeClearAllClick,
  onRarityCategoryButtonClick,
}: PalPickerFiltersProps) => {
  return (
    <Stack spacing={1}>
      <KeywordFilter keyword={keyword} onChange={onKeywordInputChange} />
      <PalTypeButtonFilter
        onTypeClick={onTypeButtonClick}
        selectedType={selectedType}
      />
      <WorkTypeButtonFilter
        onTypeClick={onWorkTypeButtonClick}
        onClearAllClick={onWorkTypeClearAllClick}
        selectedTypes={selectedWorkTypes}
      />
      <RarityCategoryButtonFilter
        onCategoryClick={onRarityCategoryButtonClick}
        selectedCategroy={selectedRarityCategory}
      />
    </Stack>
  );
};

export default PalPickerFilters;
