import ButtonGroup from '@mui/joy/ButtonGroup';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import ClearAllIcon from '@mui/icons-material/Clear';

interface ButtonFilterProps<Option> {
  isButtonGroup?: boolean;
  options: Option[];
  possibleOptions?: Set<Option>;
  selectedOptions: Option[];
  renderButtonContent: (option: Option) => React.ReactNode;
  onOptionClick: (option: Option) => void;
  onClearAllClick?: () => void;
}

const IconButtonFilter = <Option,>({
  isButtonGroup = false,
  options,
  selectedOptions,
  possibleOptions,
  renderButtonContent,
  onOptionClick,
  onClearAllClick,
}: ButtonFilterProps<Option>) => {
  const buttonNodes = options.map((option, idx) => {
    const selected = selectedOptions.includes(option);
    const color = selected ? 'warning' : 'primary';
    const variant = selected ? 'solid' : 'soft';
    const enabled = !possibleOptions || possibleOptions.has(option);
    return (
      <IconButton
        size="sm"
        disabled={!enabled}
        key={idx}
        onClick={() => onOptionClick(option)}
        color={color}
        variant={variant}>
        {renderButtonContent(option)}
      </IconButton>
    );
  });
  const content = isButtonGroup ? (
    <ButtonGroup>{buttonNodes}</ButtonGroup>
  ) : (
    buttonNodes
  );
  return (
    <Stack direction="row" spacing={1}>
      {content}
      {onClearAllClick && (
        <ButtonGroup>
          <IconButton size="sm" onClick={onClearAllClick}>
            <ClearAllIcon />
          </IconButton>
        </ButtonGroup>
      )}
    </Stack>
  );
};

export default IconButtonFilter;
