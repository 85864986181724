import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import HomeBanner from '../components/HomeBanner';
import Sheet from '@mui/joy/Sheet';
import Button from '@mui/joy/Button';
import EggIcon from '@mui/icons-material/Egg';
import { Link } from 'react-router-dom';

const HomePage = () => {
  return (
    <Stack spacing={2}>
      <HomeBanner />
      <Stack sx={{ alignItems: 'center' }} spacing={2}>
        <Typography level="h2" sx={{ flexGrow: 0, flexShrink: 0 }}>
          Palworld Breeder
        </Typography>
        <Sheet
          style={{ width: '50%', padding: '1em', borderRadius: '1em' }}
          variant="outlined">
          <Typography level="body-lg">
            Welcome to Palworld Breeder, your essential companion for optimizing
            breeding in PalWorld! Easily plan and analyze breeding combinations
            with our tools. Stay tuned for upcoming updates, including pals
            stats, resource maps and more to enhance your Palworld experience
            further!
          </Typography>
        </Sheet>
        <Button
          component={Link}
          to="/breeding"
          startDecorator={<EggIcon />}
          color="warning">
          <Typography>Breeding Calculator</Typography>
        </Button>
        <Typography level="body-xs">
          <i>More features coming soon...</i>
        </Typography>
      </Stack>
    </Stack>
  );
};

export default HomePage;
